// @ts-check
import React, { useEffect, useState } from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router";
import { AdminRoot } from "./AdminRoot.js";
import { telaniNextDataLoader, TelaniNextDownload } from "./TelaniNextDownload.js";
import { ListReferences, listReferencesLoader } from "./ListReferences/ListReferences.js";
import { UserList, userListLoader } from "./UserList/UserList.js";
import { TermsConsent, TermsConsentAction, TermsConsentLoader } from "./TermsConsent.js";
import { UserDetails, userDetailsAction, userDetailsLoader } from "./UserDetails.js";
import { ChangePassword, changePasswordLoader, changePasswordAction } from "./ChangePassword.js";
import { SubscriptionFirstStep, subscriptionFirstStepLoader } from "./SubscriptionFirstStep.js";
import { ConfirmMail } from "./ConfirmMail.js";
import { Login, loginAction } from "./Login/Login.js";
import { accountLoader, AccountRoot } from "./AccountRoot.js";
import { emailDebugLoader, EmailDebugOutput } from "./EmailDebugOutput.js";
import { ForgotPassword, forgotPasswordAction, forgotPasswordLoader } from "./ForgotPassword.js";
import { SetNewPassword, SetNewPasswordLoader } from "./SetNewPassword.js";
import { LoginRoot } from "./LoginRoot.js";
import { Reference, referenceDataLoader } from "./Reference.js";
import { ErrorPage } from "./ErrorPage.js";
import { RegisterSuccess } from "./RegisterSuccess.js";
import { Register, registerAction, registerLoader } from "./Register.js";
import { IndexPage, indexPageLoader } from "./IndexPage/IndexPage.js";
import { AdvancedUserDetails, advancedUserDetailsLoader } from "./AdvancedUserDetails.js";
import * as Sentry from "@sentry/react";
import { AdminAddUser, adminAddUserLoader, adminRegisterAction } from "./AdminAddUser.js";
import { MyPool, myPoolLoader } from "./LicensePool/MyPool/MyPool.js";
import { MyPoolAdd, myPoolAddAction, myPoolAddLoader } from "./LicensePool/MyPoolAdd.js";
import { myPoolRemoveAction } from "./LicensePool/MyPoolRemove.js";
import { AcceptInvitation, acceptInvitationAction, acceptInvitationLoader } from "./LicensePool/AcceptInvitation.js";
import { revokeInvitationAction } from "./LicensePool/RevokeInvitation.js";
import { declineInvitationAction } from "./LicensePool/DeclineInvitation.js";
import { InvitationDeclined } from "./LicensePool/InvitationDeclined.js";
import { MyPoolAddLicense, myPoolAddLicenseAction, myPoolAddLicenseLoader } from "./LicensePool/MyPoolAddLicense.js";
import { EditLicense, editLicenseAction, licenseDataLoader } from "./EditLicense.js";
import { AccountDashboard, accountDashboardLoader } from "./AccountDashboard/AccountDashboard.js";


/**
 * @param {URL} url1
 * @param {URL} url2
 * @returns {boolean}
 */
function areURLsToSamePath(url1, url2) {
    return (
        url1.protocol === url2.protocol &&
        url1.hostname === url2.hostname &&
        url1.port === url2.port &&
        url1.pathname === url2.pathname
    );
}



export function App(){

    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
        createBrowserRouter,
      );

    const adminRouter = sentryCreateBrowserRouter([
        {
            path: "/",
            loader: indexPageLoader,
            // Some search and filter operations are slow if we keep refreshing the menu, so we 
            // just revalidate the menu if a 'larger' navigation has taken place.
            shouldRevalidate: ({ currentUrl, nextUrl }) => !areURLsToSamePath(currentUrl, nextUrl),
            element: <IndexPage />,
            errorElement: <ErrorPage />,
            children:
            [
                {
                    path: "/",
                    element: <Navigate to="/account" />
                },
                {
                path: "admin",
                element: <AdminRoot />,
                errorElement: <ErrorPage />,
                children:
                [
                    {
                        path: "install-next",
                        loader: telaniNextDataLoader,
                        element: <TelaniNextDownload />
                    },
                    {
                        path: "references",
                        loader: listReferencesLoader,
                        element: <ListReferences />
                    },
                    {
                        path: "reference/new",
                        loader: referenceDataLoader,
                        element: <Reference />
                    },
                    {
                        path: "reference/:referenceId",
                        loader: referenceDataLoader,
                        element: <Reference />
                    },
                    {
                        path: "license/:licenseId",
                        action: editLicenseAction,
                        loader: licenseDataLoader,
                        element: <EditLicense />
                    },
                    {
                        path: "users",
                        children:[
                            {
                                index: true,
                                element: <UserList />,
                                loader: userListLoader,
                                shouldRevalidate: ({ currentUrl, nextUrl }) => {
                                    // Any changes in "term" search parm should be ignored, only 
                                    // for any other changes should the data be refreshed.
                                    const searchParams = new URLSearchParams(nextUrl.search);

                                    if (searchParams.has("term"))
                                    {
                                        const next_term = searchParams.get("term") ?? "";
                                        currentUrl.searchParams.set("term", next_term)
                                    }
                                    else
                                    {
                                        currentUrl.searchParams.delete("term");
                                    }
                                    
                                    const same = currentUrl.toString() === nextUrl.toString();
                                    return !same;
                                  }
                            },
                            {
                                path: "new",
                                loader: adminAddUserLoader,
                                action: adminRegisterAction,
                                element: <AdminAddUser />
                            }
                        ]
                    },
                    {
                        path: "email-debug-output",
                        loader: emailDebugLoader,
                        element: <EmailDebugOutput />
                    },

                ]
            },
            {
                path: "account",
                element: <AccountRoot />,
                loader: accountLoader,
                errorElement: <ErrorPage />,
                children:[
                    {
                        path: "/account/:userId?",
                        element: <AccountDashboard />,
                        loader: accountDashboardLoader
                    },
                    {
                        path: "/account/edit/:userId?",
                        loader: userDetailsLoader,
                        action: userDetailsAction,
                        element: <UserDetails />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "changePw/:userId?",
                        loader: changePasswordLoader,
                        action: changePasswordAction,
                        element: <ChangePassword />
                    },
                    {
                        path: "manage_subscription/:userId?",
                        loader: subscriptionFirstStepLoader,
                        element: <SubscriptionFirstStep />
                    },
                    {
                        path: "advanced-details/:userId?",
                        loader: advancedUserDetailsLoader,
                        element: <AdvancedUserDetails />
                    },
                    {
                        path: "my-pool/:userId?",
                        loader: myPoolLoader,
                        element: <MyPool />
                    },
                    {
                        path: "/account/my-pool/:userId?/add",
                        loader: myPoolAddLoader,
                        action: myPoolAddAction,
                        element: <MyPoolAdd />
                    },
                    {
                        path: "/account/pool/:poolId/remove/:targetUser",
                        action: myPoolRemoveAction
                    },
                    {
                        path: "/account/revoke-invite/:invitation",
                        action: revokeInvitationAction
                    },
                    {
                        path: "/account/my-pool/:userId?/add-license",
                        element: <MyPoolAddLicense />,
                        action: myPoolAddLicenseAction,
                        loader: myPoolAddLicenseLoader
                    }
                    
                ]
            },
            {
                path: "/register",
                element: <Register />,
                loader: registerLoader,
                action: registerAction,
                errorElement: <ErrorPage />,
            },
            {
                path: "/register/success",
                element: <LoginRoot title="Erfolg! Sie können gleich mit telani loslegen"><RegisterSuccess /></LoginRoot>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/confirm-forgotten-password/:code",
                loader: SetNewPasswordLoader,
                element: <LoginRoot title="Passwort vergessen - Neues Passwort">
                    <SetNewPassword />
                    </LoginRoot>
            },
            {
                path: "/account/terms-consent/:next",
                loader: TermsConsentLoader,
                action: TermsConsentAction,
                element: <LoginRoot title="Aktuelle AGB und Datenschutzerklärung">
                    <TermsConsent />
                    </LoginRoot>
            },
            {
                path: "confirm-mail/:code",
                element: <LoginRoot title="E-Mail Adresse bestätigen">
                    <ConfirmMail />
                    </LoginRoot>
            },
            {
                path: "/login",
                action: loginAction,
                element: <LoginRoot customClass="login" title="Anmelden">
                    <Login />
                    </LoginRoot>
            },
            {
                path: "/forgot-password",
                action: forgotPasswordAction,
                loader: forgotPasswordLoader,
                element: <LoginRoot title="Passwort vergessen">
                    <ForgotPassword />
                    </LoginRoot>
            },
            {
                path: "/invitation/:invitation/:invitationCode",
                loader: acceptInvitationLoader,
                action: acceptInvitationAction,
                element: <LoginRoot title="telani Einladung">
                    <AcceptInvitation />
                </LoginRoot>
            },
            {
                path: "/decline-invitation/:invitation/:invitationCode",
                action: declineInvitationAction
            },
            {
                path: "/invitation-declined",
                element: <LoginRoot title="telani Einladung">
                    <InvitationDeclined />
                    </LoginRoot>
            }
        ]}
    ], { future: {
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_skipActionErrorRevalidation: true,
      }});

    return <RouterProvider router={adminRouter} />
}