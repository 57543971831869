// @ts-check
import React, { Component } from "react"

import "./Step.css";

export function Step({Title, children}) {
    return <div className="checkout-step">
                <h3>{Title}</h3>
                {children}
            </div>;
}