// @ts-check
import React from "react"

import "./NewsletterOptIn.css"

export function NewsletterOptIn({currentlyLoading = false}) 
{
    return (<>
    Wir möchten Ihnen gerne etwa einmal im Monat einen E-Mail Newsletter schicken:
    <div className="form-group">
        <div>
            <div className="checkbox">
                <label className="required" htmlFor="user_register_email_newsletter"> 
                    <input type="checkbox" disabled={currentlyLoading}
                        id="user_register_email_newsletter" name="user_register[email_newsletter]"
                        value="1"             />

                    <span>
                    Beim Newsletter anmelden (optional)
                    </span>
                </label>
            </div>
        </div>
    </div> 
    <p className="small-text">Sie können sich jederzeit abmelden, indem Sie auf den Link in der Fußzeile der E-Mail klicken.
            Informationen zu unseren Datenschutzpraktiken finden Sie <a tabIndex={78}  href="/datenschutz" >hier.</a></p>
    </>);
}