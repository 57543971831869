// @ts-check
import React from 'react';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { App } from './App.js';

import "bootstrap/dist/css/bootstrap.min.css";
import * as bootstrap from 'bootstrap';
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
  } from "react-router";

/**
 * @param {string} [next] - The next Url to go after login
 */
export function GoToLogin(next = "") {
    let url = "/login";
    if (next !== ""){
        url += "?next=" + encodeURIComponent(next);
    }
    window.location.href = url;
    return Promise.reject();
}

export function InitializeReact(){
    Sentry.init({
        enabled: __ENVIRONMENT__ !== "development",
        environment: __ENVIRONMENT__,
        dsn: "https://ec6f052ae1083ebb418b268f5ef39163@o4507691586682880.ingest.de.sentry.io/4507782517489744",
        integrations: [
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        tunnel: "/webapi/sentry",
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,
      });

    const telaniAdminContainer = document.getElementById('telaniAdmin');
    if (telaniAdminContainer !== null){
        const root = createRoot(telaniAdminContainer);
        root.render(
            <StrictMode>
                <App />
            </StrictMode>
        )
    }
    else{
        console.error("This page should contain the admin container");
    }
}