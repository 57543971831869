// @ts-check
import React, { useState, useEffect, useRef} from "react";
import { RegisterAdvantages } from "./RegisterAdvantages/RegisterAdvantages.js";
import { Form, redirect, useActionData, useLoaderData, useNavigation } from "react-router";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { TermsAndPrivacy } from "./UserFormComponents/TermsAndPrivacy.js";
import { PersonalDetailsForm } from "./UserFormComponents/PersonalDetailsForm.js";
import { NewsletterOptIn } from "./NewsletterOptIn/NewsletterOptIn.js";

export async function registerLoader(){
    const res = await fetch(__API_URL__ + '/webapi/register-token');
    const data = await res.json();
    return {token: data["token"], siteKey: data["captcha-site-key"]};
}

export async function registerAction({ request, params }){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    const newPassword1 = formData.get("user_register[plainPassword][first]");
    const newPassword2 = formData.get("user_register[plainPassword][second]");
    if (newPassword1.length < 8){
        return ({success: false, error: "Ihr Passwort muss mindestens 8 Zeichen lang sein.", target: "password"});
    }
    if (newPassword1 !== newPassword2){
        return ({success: false, error: "Die Passwörter stimmen nicht überein.", target: "password"});
    }
    const res = await fetch(__API_URL__ + "/webapi/register", {method: "POST", body: formData});

    const data = await res.json();
    if (data.success){
        console.log("Successfully registered");
        return redirect("/register/success");
    }
    else {
        console.log("Errors occurred");
        return data;
    }
}

export function Register(){
    let actionData = useActionData();

    const {state: loadingState} = useNavigation();

    const currentlyLoading = loadingState === "submitting" || loadingState === "loading";

    let message = null;
    let password_message = null;

    if (actionData !== undefined && actionData !== null && !actionData.success){
        if (actionData.target === "password"){
            password_message = {isError: true, message: actionData.error};
            message = {isError: true, message: actionData.error};
        }
        else{
            message = {isError: true, message: actionData.error};
        }
    }
        
    const {token, siteKey } = useLoaderData();
    const captchaRef = useRef(null);

    const [captchaResponse, setCaptchaResponse] = useState("");

    function handleVerificationSuccess(token, ekey){
        setCaptchaResponse(token);
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
      };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
      };

      let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
      let password_message_block = password_message !== null ? <div className={`alert ${password_message.isError ? "alert-danger" : "alert-success"}`}>{password_message.message}</div> : null;

    return <>
    <div className="wrapper style2">
	    <section id="main" className="container narrowcontainer page-container">
        		<header className="major">
			<h2>Starten Sie Ihren kostenlosen Test von telani</h2>
		</header>
                <section id="content" className="content">
                            <section className="register">
    <RegisterAdvantages />
    <article className="centeredsmall">
        <Form method="post">
        <div>    
            <label htmlFor="user_register_email" className="required">E-Mail Adresse
                <input type="email" id="user_register_email" 
                disabled={currentlyLoading}
                name="user_register[email]" required={true} autoComplete="email" className="form-control" />
            </label>
    </div>
    <PersonalDetailsForm currentlyLoading={currentlyLoading} />
    <div>
        <label htmlFor="user_register_plainPassword_first" className="required">Passwort (min 8 Zeichen)
            <input type="password"
            disabled={currentlyLoading}
            id="user_register_plainPassword_first" name="user_register[plainPassword][first]" required={true} autoComplete="new-password" className="form-control" />
        </label>
    </div>
    <div>    
        <label htmlFor="user_register_plainPassword_second" className="required">Passwort wiederholen
            <input type="password" disabled={currentlyLoading} 
            id="user_register_plainPassword_second" name="user_register[plainPassword][second]" required={true} autoComplete="new-password" className="form-control" />
        </label>
    </div>
    {password_message_block}
    <div>    
        <span className="fake-label">Sind Sie ein Roboter?</span>
        <HCaptcha sitekey={siteKey} 
            ref={captchaRef}
            onError={onError}
            onExpire={onExpire}
            onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)} /> 
    </div>

    <h3>E-Mail Newsletter</h3>
    <NewsletterOptIn currentlyLoading={currentlyLoading} />
    <h3>AGB und Datenschutzerklärung</h3>
    <TermsAndPrivacy currentlyLoading={currentlyLoading} />
    
    <input type="hidden" id="hCaptchaResponse" name="hCaptchaResponse" value={captchaResponse} />
    <input type="hidden" id="user_register__token" name="user_register[_token]" value={token} />
    <div className="form-group" style={{overflow: "hidden"}}>
        { message_block }
        <input type="submit" disabled={currentlyLoading}
                value="Registrieren" style={{display: "block", margin: "30px auto 0"}} className="btn btn-primary" />
    </div>
        </Form>
                </article>
            </section>
        </section>
	</section>
</div>
    </>;
}
