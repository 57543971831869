// @ts-check
import { InitializeTracking } from './matomo.js';
import { InitializeReact } from './react-entry.js';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import '../css-src/main.css'

library.add(
    faAngleDown,
    faBars,
    faLinkedinIn,
    faFacebookF
  );

dom.watch();

var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */

_paq.push(['addDownloadExtensions', "appinstaller"]);
_paq.push(['setCustomDimension', 1, getColorScheme()]);
_paq.push(['enableLinkTracking']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['requireCookieConsent']);
_paq.push(['enableJSErrorTracking']);
_paq.push(['setTrackerUrl', 'https://status.telani.net/status.php?test=fail']);
_paq.push(['setSiteId', '11']);
_paq.push(['trackPageView']);

/**
 * @returns {string}
 */
function getColorScheme(){
    if ( window.matchMedia("(prefers-color-scheme:dark)").matches) {
        return "dark";
    } else {
        return "light";
    }
}

window.addEventListener("DOMContentLoaded", function (event) {
    InitializeReact();
});
