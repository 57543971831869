// @ts-check
import React, { useState } from "react"
import { useTitle } from "./AccountRoot.js";
import { HeaderedSection } from "./HeaderedSection.js";
import { DeviceManagement } from "./DeviceManagement/DeviceManagement.js";
import { ActiveFeaturesBlock } from "./ActiveFeaturesBlock.js";
import { Form, redirect, useActionData, useLoaderData, useParams, useRevalidator } from 'react-router';
import { GeneralAdministration } from "./GeneralAdministration.js";
import FormGroup from "./FormGroup.js";


export async function userDetailsAction({ request, params }){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();

    if (formData.get("isAdmin") !== "true"){
        const response = await fetch(__API_URL__ + `/webapi/account/${formData.get("userId")}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                greeting: formData.get("greeting"),
                firstName: formData.get("firstName"),
                lastName: formData.get("lastName"),
                phone_number: formData.get("phoneNumber"),
                email_newsletter: formData.get("emailNewsletter"),
                _token: formData.get("token")
            })
        });
        if (response.status === 401){
            //return redirect()
        }
        const data = await response.json();
        if (!data.success){
            return {isError: true, message: data.error};
        }
        else{
            return {isError: false, message: "Erfolgreich gespeichert."};
        }
    }
    else{
        const response = await fetch(__API_URL__ + `/webapi/admin_account/${formData.get("userId")}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                greeting: formData.get("greeting"),
                firstName: formData.get("firstName"),
                lastName: formData.get("lastName"),
                phone_number: formData.get("phoneNumber"),
                email_newsletter: formData.get("emailNewsletter"),
                licenseType: formData.get("licenseType"),
                currentLicenseExpiration: formData.get("currentLicenseExpiration"),
                disableEmail: formData.get("disableEmail"),
                fixedPasswordAccount: formData.get("fixedPasswordAccount"),
                freeAccount: formData.get("freeAccount"),
                maximumAPIKeys: formData.get("maximumAPIKeys"),
                _token: formData.get("token")
            })
        });
        if (response.status === 401){
            // redirect
        }
        const data = await response.json();
        if (!data.success){
            return {isError: true, message: data.error};
        }
        else{
            return {isError: false, message: "Erfolgreich gespeichert."};
        }
    }
}


async function fetchUser(userId, request){
    let url = "/webapi/userinfo";
    if (userId !== null && userId !== undefined){
        url += `/${userId}`;
    }
    let res = await fetch(__API_URL__ + url,{
        signal: request.signal
    });
    if (res.status === 401){
        throw new Error("Not logged in");
    }
    if (res.status === 404){
        throw new Response("Not Found", { status: 404 });
    }
    const data = await res.json();
    let roles = data.roles ?? [];
    if (!Array.isArray(roles))
    {
        roles = Object.values(roles);
    }
    return {data: data, roles: roles}
}

export async function userDetailsLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);

    let token_promise = fetch(__API_URL__ + '/webapi/userDetailsToken')
        .then(response => {
            if (response.status === 401){
                throw new Error('Not logged in');
            } 
            return response.json();
    }).catch(err => console.error(err));

    try{
        let current_user_data = null;
        let current_user_roles = null;
        const {data, roles} = await fetchUser(params.userId, request);
        if (params.userId === null || params.userId === undefined){
            current_user_data = data;
            current_user_roles = roles;
        }
        else{
            const {data: current, roles: current_roles} = await fetchUser(null, request);
            current_user_data = current;
            current_user_roles = current_roles;
        }

        let the_admin_data = {
            licenseType: 2,
            currentLicenseExpiration: "",
            disableEmail: false,
            fixedPasswordAccount: false,
            freeAccount: false,
            maximumAPIKeys: 0,
            emailConfirmed: false,
            emailConfirmCode: "",
        };
        if (current_user_roles.includes("ROLE_ADMIN")){
            console.log("Fetching admin data");
            const admin_response = await fetch(__API_URL__ + `/webapi/admin-userinfo/${data.id}`);
            if (admin_response.status === 401){
                return redirect(login_url);
            }
            const admin_response_data = await admin_response.json();
            the_admin_data = {
                licenseType: admin_response_data.licenseType === null ? "2" : admin_response_data.licenseType,
                currentLicenseExpiration: admin_response_data.currentLicenseExpiration === null ? "" : admin_response_data.currentLicenseExpiration,
                disableEmail: admin_response_data.disableEmail,
                fixedPasswordAccount: admin_response_data.fixedPasswordAccount,
                freeAccount: admin_response_data.freeAccount,
                maximumAPIKeys: admin_response_data.maximumAPIKeys === null ? "0" : admin_response_data.maximumAPIKeys,
                emailConfirmed: admin_response_data.emailConfirmed,
                emailConfirmCode: admin_response_data.emailConfirmCode,
            };
        }
        const loader_data = {
            init_nextCustomer: data.nextCustomer,
            init_user: {
                id: data.id,
                email: data.email,
                greeting: data.greeting,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data["phone_number"],
                emailNewsletter: data["email_newsletter"],
                adminRights: roles.includes("ROLE_ADMIN"),
            },
            logged_in_user: {
                id: current_user_data.id,
                email: current_user_data.email,
                greeting: current_user_data.greeting,
                firstName: current_user_data.firstName,
                lastName: current_user_data.lastName,
                phoneNumber: current_user_data["phone_number"],
                emailNewsletter: current_user_data["email_newsletter"],
                adminRights: current_user_roles.includes("ROLE_ADMIN"),
            },
            token: (await token_promise)["token"],
            admin_data: the_admin_data
        };
        return loader_data;
    }
    catch (e){
        if (e.message === "Not logged in"){
            return redirect(login_url);
        }
        else{
            throw e;
        }
    }
}

const UserDetailsInner = ({userId, nextCustomer, init_user, logged_in_user, token, admin_data, message}) => {

    const revalidator = useRevalidator();
    const isAdmin = logged_in_user.adminRights
    const loggedInUserId = logged_in_user.id;
    const [formData, setFormData] = useState(init_user);
    const [adminFormData, setAdminFormData] = useState(admin_data);

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    
    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
          ...formData,
          [name]: value
        });
      };
    
    const handleAdminInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setAdminFormData({
          ...adminFormData,
          [name]: value
        });
      };

    function clearCurrentLicenseExpiration(){
        setAdminFormData({
            ...adminFormData,
            currentLicenseExpiration: ""
          });
    }

    return  <>
        <div className="form-group">
            <label>E-Mail Adresse
                <input readOnly type="text" 
                    className="form-control" 
                    data-toggle="tooltip" data-placement="top"
                    title="Um Ihre E-Mail Adresse zu ändern, wenden Sie sich bitte an unseren Kundensupport." value={formData.email} />
                <span className="smallNote">Um Ihre E-Mail Adresse zu ändern, wenden Sie sich bitte an unseren Kundensupport.</span>
            </label>
        </div>
        <Form method="post">
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="isAdmin" value={isAdmin} />
            <input type="hidden" name="userId" value={userId} />
            <FormGroup required={true} label_for="greeting" label="Anrede"> 
                <select value={formData.greeting}
                     onChange={handleInputChange} 
                    name="greeting" className="" id="greeting">
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                    <option value="Herr Dr.">Herr Dr.</option>
                    <option value="Frau Dr.">Frau Dr.</option>
                    <option value=""></option>
                </select>
            </FormGroup>
            <FormGroup label_for="firstName" label="Vorname"> 
                <input type="text"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    id="firstName"
                    name="firstName" className="form-control" />
            </FormGroup>
            <FormGroup label_for="lastName" label="Nachname">
                <input type="text" 
                    value={formData.lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    id="lastName"
                    className="form-control" />
            </FormGroup>
            <FormGroup label_for="phoneNumber" label="Telefonnummer">
                <input type="text"
                    value={formData.phoneNumber} 
                    onChange={handleInputChange}
                    name="phoneNumber" 
                    id="phoneNumber"
                    className="form-control" />
            </FormGroup>

        <span className="fake-label">E-Mail Newsletter</span>
        <div className="form-group">
            <div>
                <div className="checkbox">
                    <label className="required"> 
                        <input type="checkbox" 
                            checked={formData.emailNewsletter} 
                            onChange={handleInputChange} 
                            name="emailNewsletter" />
                        <span>
                            Beim Newsletter anmelden (optional)
                        </span>
                    </label>
                </div>
            </div>
        </div>

    {isAdmin &&
<div id="admin_user_account">
    <FormGroup label_for="admin_user_account_licenseType" required={true} label="Lizenztyp (Admin)">
        <select id="admin_user_account_licenseType" value={adminFormData.licenseType} onChange={handleAdminInputChange} name="licenseType" className="form-control">
            <option value="1">Test-Next</option>
            <option value="2">Keine</option>
            <option value="3">telani ("Classic")</option>
            <option value="6">telani Next</option>
            <option value="8">Insight</option>
            <option value="9">onSite</option>
            <option value="10">Concept</option>
        </select>
    </FormGroup>
    <FormGroup label_for="admin_user_account_currentLicenseExpiration" 
    label="Aktuelle Lizenz-Laufzeit (Admin)">
        <input type="date" value={adminFormData.currentLicenseExpiration} onChange={handleAdminInputChange} 
            id="admin_user_account_currentLicenseExpiration" 
            name="currentLicenseExpiration" min="2024-09-01" 
            className="clearable-date-field form-control" />
            <button type="button" onClick={clearCurrentLicenseExpiration} className="right">Datum löschen</button>
    </FormGroup>
    <div className="form-group">
        <div>
        <FormGroup special_class="checkbox" required={true} label_for="admin_user_account_disableEmail">
            <input type="checkbox" checked={adminFormData.disableEmail} onChange={handleAdminInputChange} 
            id="admin_user_account_disableEmail" name="disableEmail" value="1" />
            <span>
            E-Mailzustellung komplett deaktivieren
            </span>
        </FormGroup> 
</div></div>
        <FormGroup special_class="checkbox" required={true} 
            label_for="admin_user_account_fixedPasswordAccount">
            <input type="checkbox" checked={adminFormData.fixedPasswordAccount}
            onChange={handleAdminInputChange}
            id="admin_user_account_fixedPasswordAccount"
            name="fixedPasswordAccount"
            value="1" />
            <span>
            Benutzer kann Passwort nicht ändern (Schulungsaccount)
            </span>
        </FormGroup>

        <FormGroup special_class="checkbox" required={true} 
            label_for="admin_user_account_free">
            <input type="checkbox" checked={adminFormData.freeAccount}
            onChange={handleAdminInputChange}
            id="admin_user_account_free"
            name="freeAccount"
            value="1" />
            <span>
            Benutzer verwendet telani kostenlos (Interne Mitarbeiter und Freunde-der-App)
            </span>
        </FormGroup>
    <FormGroup label_for="admin_user_account_maximumAPIKeys" label="Anzahl von Geräten die gleichzeitig angemeldet sein können (Nur 'Classic') (Admin)">
            <input type="number" value={adminFormData.maximumAPIKeys} onChange={handleAdminInputChange} 
            id="admin_user_account_maximumAPIKeys" name="maximumAPIKeys" autoComplete="off" className="form-control" />
    </FormGroup>
</div>
    }
{message_block}

            <div className="form-group">
                
                <button type="submit"
                 className="primary">Speichern</button>
            </div>
            </Form>
                { !isAdmin &&
                <HeaderedSection title="Konto schließen">
                    <p>Um Ihr Konto bei uns zu schließen, wenden Sie sich bitte an unseren <a href="/kontakt">Kundensupport</a>.</p>
                </HeaderedSection>}
                { isAdmin && userId !== undefined && userId !== null &&
                <div className="admin-area">
                    <HeaderedSection title="Administration">
                        <GeneralAdministration 
                            userIsAdmin={init_user.adminRights}
                            userHasEmailConfimed={admin_data.emailConfirmed}
                            confirmCode={admin_data.emailConfirmCode} 
                            userId={userId}
                            onAdminUpdate={() => revalidator.revalidate()}
                            isOwnUser={userId === loggedInUserId} />
                    </HeaderedSection>
                    <HeaderedSection title="Aktivierte Features">
                        <ActiveFeaturesBlock userId={userId} />
                    </HeaderedSection>
                </div>
                }
                { nextCustomer && <DeviceManagement userId={userId} isAdmin={isAdmin} /> }
            </>
}

export function UserDetails() {

    const {init_nextCustomer: nextCustomer, init_user, logged_in_user, token, admin_data} = useLoaderData();

    const userId = init_user.id;

    const actionData = useActionData();
    let message = null;
    if (actionData !== undefined && actionData !== null){
        message = {isError: actionData.isError, message: actionData.message};
    }

    useTitle("Konto Informationen");

    return <UserDetailsInner 
            key={userId} 
            userId={userId}
            nextCustomer={nextCustomer}
            init_user={init_user}
            logged_in_user={logged_in_user}
            token={token}
            admin_data={admin_data}
            message={message} />
}