// @ts-check
import React, { useState, useEffect} from "react";
import { Outlet, NavLink, Routes, Route, useOutletContext } from "react-router";
import { MainWrapper } from "./MainWrapper.js";


export function useTitle(title){
  const { setTitle } = useOutletContext();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};

export function AdminRoot(){

    const [title, setTitle] = useState("");

    function linkStyle({isActive}){
      return isActive ? "small button" :  "small button alt";
    }

    return (      
        <MainWrapper majorTitle={title}>
            <ul className="nav telani-nav-tabs" role="tablist">
                <li role="presentation">
                    <NavLink viewTransition className={linkStyle} to="/admin/users" role="tab">Benutzer</NavLink>
                </li>
                <li role="presentation">
                    <NavLink viewTransition className={linkStyle} to="/admin/references" role="tab">Referenzen</NavLink>
                </li>
                <li role="presentation">
                    <NavLink  viewTransition className={linkStyle} to="/admin/install-next" role="tab">Nightly</NavLink>
                </li>
                <li role="presentation">
                    <NavLink  viewTransition className={linkStyle} to="/admin/email-debug-output" role="tab">Mails</NavLink>
                </li>
                <li role="presentation">
                    <a className="small button alt extern-link" href="https://blog.telani.net/wp-admin" role="tab">Blog</a>
                </li>
                <li role="presentation">
                    <a className="small button alt extern-link" href="https://track.gb-t.de?idSite=11" role="tab">Analytics</a>
                </li>
            </ul>
            <Outlet context={{ title, setTitle }}/>
        </MainWrapper>
    );
}
