// @ts-check

export function UnInitializeNavigation(){
    // this is for react strict mode.

    const elems = document.getElementsByClassName("wp-block-navigation-submenu");
    for (let i = 0; i < elems.length; i++) {
        const element = elems[i];
        if (element.classList.contains("has-child")){
            if (element.firstChild !== null && element.firstChild.nodeName === "I"){
                element.removeChild(element.firstChild);
            }     
        }
    }

    document.body.classList.add("is-preload");

    var navButton = document.getElementById("navButton");
    if (navButton !== null){
        navButton.remove();
    }
    const nav = document.getElementById("nav");
    if (nav !== null){
        nav.removeEventListener("click", navClick);
    }
    document.body.removeEventListener("click", bodyClick);

    const pageWrapper = document.getElementsByClassName("parallax");
    if (pageWrapper !== null && pageWrapper.length > 0){
        pageWrapper[0].removeEventListener("scroll", handleEventHideMenu);
    }

    window.removeEventListener("keyup", windowKeyUp);
}

export function InitializeNavigation(){

    const elems = document.getElementsByClassName("wp-block-navigation-submenu");
    for (let i = 0; i < elems.length; i++) {
        const element = elems[i];
        if (element.classList.contains("has-child")){
            const icon = document.createElement("i");
            icon.classList.add("icon", "solid", "fas", "fa-angle-down");
            element.prepend(icon);        
        }
    }

    document.body.classList.remove("is-preload");
    
    // Title Bar.
    var navButton = document.createElement("div");
    navButton.id = "navButton";

    var navLink = document.createElement("a");
    navLink.href = "#navPanel";
    navLink.classList.add("toggle");

    var bars = document.createElement("span");
    bars.classList.add("fas");
    bars.classList.add("fa-bars");
    navLink.appendChild(bars);

    navButton.appendChild(navLink);
    document.body.appendChild(navButton);

    navButton.addEventListener("click", navButtonClicked);

    const nav = document.getElementById("nav");
    if (nav !== null){
        nav.addEventListener("click", navClick);
    }
    document.body.addEventListener("click", bodyClick);

    const pageWrapper = document.getElementsByClassName("parallax");
    if (pageWrapper !== null && pageWrapper.length > 0){
        pageWrapper[0].addEventListener("scroll", handleEventHideMenu, {passive: true});
    }

    window.addEventListener("keyup", windowKeyUp);
}

function navClick(ev){
    var source = ev.target;

    if (!(source instanceof HTMLAnchorElement)){
        const links = source.getElementsByTagName('a');
        if (links !== null && links.length > 0){
            source = links[0];
        }
        else if (source !== null && source.parentElement instanceof HTMLAnchorElement){
            source = source.parentElement;
        }
    }

    if (source === null || !source.href || source.href == "#" || source.href == ""){
        return;
    }

    if (source.href.startsWith("#") || source.href.startsWith("/#") || source.href.startsWith(`${location.protocol}//${location.host}${location.pathname}#`)) {
        hideMenu();
    }
}

function handleEventHideMenu(ev){
    hideMenu();
}

function bodyClick(ev){
    var element = document.getElementById('navButton');

    if (element === null || (ev.target !== element && !element.contains(ev.target))) {
        hideMenu();
    }
}

function windowKeyUp(e){
    // Collapse all menus if the user hits escape.
    if (e.key == "Escape") {
        hideMenu();
    }
}

function navButtonClicked(ev){
    var menu = document.getElementById("react-menu");
    if (menu !== null){
        menu.classList.toggle("menu-open");
        ev.preventDefault();
    }
}

function hideMenu(){
    var menu = document.getElementById("react-menu");
    if (menu !== null && menu.classList.contains("menu-open")){
        menu.classList.remove("menu-open");
    }  
}