// @ts-check
import React, { useEffect, useState } from "react"
import { NavLink, redirect, useLoaderData, useNavigate } from "react-router";
import { useTitle } from "../AdminRoot.js";

import "./ListReferences.css";

export async function listReferencesLoader({ request }){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let res = await fetch(__API_URL__ + '/webapi/references')
    if (res.status === 401){
        return redirect(login_url);
    }
    return await res.json();
}

export function ListReferences(){
    const references = useLoaderData();
    const navigate = useNavigate();
    useTitle("Referenzen");

    function handleClick(url){
        navigate(url);
    }

    const reference_blocks = references.map(r => {
        return <tr key={r.id} className="clickable-row" onClick={() => handleClick(`/admin/reference/${r.id}`)}>
        <td>{ r.id }</td>
        <td>{ r.title }</td>
        <td>{ r.teaserText }</td>
    </tr>
    })

    return <>
    	<div className="clearfix">
		    <NavLink className="button alt small btn-default pull-right" to="/admin/reference/new">Erstellen</NavLink>
	    </div>
    
        <table className="table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Titel</th>
                    <th>Teaser</th>
                </tr>
            </thead>
            <tbody>
                { reference_blocks }
            </tbody>
        </table>
   </>;
}