// @ts-check
import React from "react"
import { useTitle } from "../AccountRoot.js";
import { redirect, useLoaderData } from "react-router";
import { fetchUser } from "../APIHelpers/Users.js";
import "./AccountDashboard.css";


export async function accountDashboardLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    try{
        const user = await fetchUser(params.userId, request.signal);
        return {user};
    }
    catch(error){
        if (error.message === "not logged in"){
            return redirect(login_url)
        }
    }
    return null;
}

const licenses = {
    1: "eine telani Next Testversion",
    2: "",
    3: "telani",
    6: "telani Next",
    8: "telani Insight",
    9: "telani onSite",
    10: "telani Concept"
}

export function LicenseTypeToString(licenseType)
{
    return licenses[licenseType];
}

const licenses_desc = {
    1: "",
    2: "",
    3: ", um intuitiv und einfach leistungsstarke Brandfallsteuermatrizen erstellen und exportieren zu können.",
    6: " mit dem vollen und interaktiven Funktionsumfang, um intuitiv und einfach leistungsstarke Brandfallsteuermatrizen erstellen und exportieren zu können.",
    8: ". Mit diesem Viewer können Sie die gesamte Brandfallsteuermatrix interaktiv analysieren und kommentieren.",
    9: ". Dieser Viewer hilft Ihnen, die für Sie relevanten Bauteile schnell und übersichtlich zu finden und zu kommentieren.",
    10: ""
}

export function AccountDashboard() 
{
    useTitle("telani Konto");

    const {user} = useLoaderData();

    const hasName = user.greeting !== "" && user.lastName !== "";

    const hasLicense = user.licenseType !== 2;

    let license_description = null;
    if (user.licenseType === 1){
        license_description = <p>Derzeit nutzen Sie eine <strong>Testversion von telani Next</strong>. Während der Testphase können Sie alle Funktionen von telani Next ausprobieren und selbst erleben, 
            wie einfach es ist, mit telani eine Brandfallsteuermatrix zu erstellen und zu exportieren.</p>;
    } else if (hasLicense){
        license_description = <p>Derzeit haben Sie Zugriff auf <strong>{LicenseTypeToString(user.licenseType)}</strong>{licenses_desc[user.licenseType]}</p>;
    }
    else{
        license_description = <p>Sie verfügen derzeit über <strong>keine gültige telani-Lizenz</strong>. Kontaktieren Sie uns bei Fragen unter <a href="mailto:info@telani.net">info@telani.net</a></p>;
    }

    return (<><div className="dashboard"><h3>Herzlich Willkommen{hasName && `, ${user.greeting} ${user.lastName}`}!</h3>
    {license_description}

    <p>Um Ihre Lizenz zu verwenden, melden Sie sich einfach in telani mit Ihrem telani-Konto an.<br />
    Falls Sie telani noch nicht installiert haben, können Sie die Software hier <strong>herunterladen</strong>:</p>
    <p className="center"><a className="button small alt" href="/download">Zur Download Seite</a></p>

    <p>Unser <strong>Hilfe-Center</strong> bietet Ihnen neben ausführlichen Anleitungen sowie weiterführenden Informationen auch einen <strong>Quickstart</strong> zur 
    schnellen Einarbeitung in das Programm.</p>
    <p className="center"><a className="button small alt" href="https://help.telani.net">Zum Hilfe-Center</a></p>

    <p>Unser Support-Team steht Ihnen bei technischen Fragen oder Problemen gerne zur Verfügung.</p>
    <p className="center"><a className="button small alt" href="mailto:support@telani.net">E-Mail-Support</a></p>
    <p>Möchten Sie eine Schulung für effizienteres Arbeiten und den optimalen Einsatz von telani sowie zur Erstellung von Brandfallsteuermatrizen? Kontaktieren Sie uns einfach unter <a href="mailto:info@telani.net">info@telani.net</a></p>
    </div></>);
}