// @ts-check
import React from "react";
import { json, Navigate, NavLink, useLoaderData, useSearchParams } from "react-router";
import { useTitle } from "./AdminRoot.js";
import { GoToLogin } from "./react-entry.js";

export async function emailDebugLoader({request}){

    const url = new URL(request.url);
    const whichMail = url.searchParams.get("q") ?? "welcome";

    const res = await fetch(__API_URL__ + "/webapi/email-debug-output/" + whichMail,{
        signal: request.signal
    });
    if (res.status === 401){
        GoToLogin();
    }
    return res;
}

export function EmailDebugOutput(){
    useTitle("E-Mail Templates");
    let [searchParams, setSearchParams] = useSearchParams();

    const {content, title} = useLoaderData();

    const search_q = searchParams.get("q");

    if (!searchParams.has("q")){
        return <Navigate to={"?q=welcome"} />
    }

    const mails = [
        {slug: "welcome", title: "Willkommen"},
        {slug: "register", title: "Registrierung"},
        {slug: "almost-over", title: "Test fast vorbei"},
        {slug: "forgotten", title: "Passwort vergessen"},
        {slug: "help-install", title: "Hilfe bei Installation"},
        {slug: "test-over", title: "Test vorbei"},
        {slug: "invite", title: "Einladung"},
        {slug: "license-removed", title: "Viewer-Lizenz entfernt"},
    ]

    const markup = { __html: content };
    return <>
        <p>Das Design der E-Mails ist ähnlich, aber nicht identisch zu diesem Design:</p>
        {mails.map(item => <NavLink key={item.slug} className={({isActive}) => (isActive && search_q === item.slug) ? 'miniNavLink csvexportbutton active' : 'miniNavLink csvexportbutton'} to={"?q=" + item.slug}>{item.title}</NavLink>)}
        <h2 className="email-debug">{title}</h2>
        <div dangerouslySetInnerHTML={markup}></div>
    </>
}