import React, {useState} from "react";
import { json, redirect, useFetcher } from "react-router";
import { LicenseTypeToString } from "./MyPool/MyPool.js";
import Modal from 'react-bootstrap/esm/Modal.js';
import Button from 'react-bootstrap/esm/Button.js';

function CancelLicenseConfirmation({show, onHide, onRemove}){
    return (
        <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Lizenz entfernen
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
            Sind Sie sicher, dass Sie diese Lizenz entfernen möchten? Der Nutzer kann diese dann nicht mehr verwenden. Sie können diese Lizenz dann erneut verteilen.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={onHide}>Abbrechen</Button>
            <Button size="sm" variant="primary" onClick={onRemove}>Lizenz entfernen</Button>
        </Modal.Footer>
        </Modal>
    );
}

export function PoolMember({email, licenseType, memberId, poolId, member}){
    const [showConfirm, setShowConfirm] = useState(false);
    const fetcher = useFetcher({key: "remove-member-" + memberId});


    const sendRemoval = () => {
        fetcher.submit({
            userId: memberId,
        }, {
            method: "POST",
            action: "/account/pool/" + poolId + "/remove/" + memberId
        })
        setShowConfirm(false);       
    };
    const hasName = (member.greeting !== "" && member.lastName !== "") || (member.firstName !== "" && member.lastName != "");

    return  <div className="licenseItem">
    <strong>{hasName ? `${member.greeting} ${member.firstName} ${member.lastName} ("${email}")` : email}</strong>
    <p>Lizenz: {LicenseTypeToString(licenseType)}</p>
    <button className="small button alt csvexportbutton" type="button" onClick={() => setShowConfirm(true)}>Entfernen</button>
    <CancelLicenseConfirmation show={showConfirm} onHide={() => setShowConfirm(false)} onRemove={sendRemoval} />
    </div>;
}