// @ts-check
import React, { useRef, useState } from "react";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate } from "react-router";
import FormGroup from "../FormGroup.js";
import { useTitle } from "../AccountRoot.js";

export async function myPoolAddLicenseAction({ request, params }){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let url = "/webapi/userinfo";
    if (params.userId !== undefined){
        url = url + "/" + params.userId;
    }
    let user_res = await fetch(__API_URL__ + url,{
        signal: request.signal
    });
    if (user_res.status === 401){
        return redirect(login_url);
    }
    const user = await user_res.json();
    const res_pool = await fetch(__API_URL__ + "/webapi/pool/" + user.licensePool, {
        signal: request.signal
    });
    const pool = await res_pool.json();

    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    console.log("SUBMITTING INVITATION");
    const poolId = pool.id;

    const res = fetch(__API_URL__ + "/webapi/create-license/" + poolId, {
        signal: request.signal,
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            userid: formData.get("userid"),
            expiration: formData.get("expiration_date"),
            licensetype: formData.get("licenseType")
        })
    });
    return res;
}


export async function myPoolAddLicenseLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let url = "/webapi/userinfo";
    if (params.userId !== undefined){
        url = url + "/" + params.userId;
    }
    let res = await fetch(__API_URL__ + url, {
        signal: request.signal
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    const user = await res.json();
    if (user.licensePool !== null && user.licensePool !== ""){
        let res = await fetch(__API_URL__ + "/webapi/pool/" + user.licensePool,
            {
            signal: request.signal
        });
        if (res.status === 401){
            return redirect(login_url);
        }
        const pool = await res.json();
        console.log(pool);
        const members = [];
        for (const member_id of pool.members){
            const res = await fetch(__API_URL__ + "/webapi/userinfo/" + member_id,{
                signal: request.signal
            });
            if (res.status === 401){
                return redirect(login_url);
            }
            const member = await res.json();
            
            console.log(member);
            if (member.licenseType == 6 || member.licenseType == 1){
                members.push(member);
            }
        }
        return {pool: pool, members: members};
    }
    else{
        return null;
    }
    
}


export function MyPoolAddLicense(){
    const dateElement = useRef(null);
    const navigate = useNavigate();
    let message = null

    const {pool, members} = useLoaderData();

    console.log(pool);

    const action_result = useActionData();
    if (action_result !== undefined && action_result !== null){
        if (action_result.success){
            message = {isError: false, message: "Lizenz erfolgreich hinzugefügt"};
        }
        else{
            message = {isError: true, message: "Es ist ein Fehler aufgetreten"};
        }
    }

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    
    useTitle("Lizenz erzeugen");

    const clearDate = () => {
        if (dateElement.current !== null){
            dateElement.current.value = null;
        }
    }

    return <>
    <a href="#" onClick={() => navigate(-1)}>Zurück</a>
    <Form method="POST">
        <input type="hidden" name="poolid" value={pool.id} />
        <FormGroup label_for="email" label="Eigentümer (nicht Nutzer)">
            <select required id="email" name="userid">
                {members.map(mem => <option key={mem.id} value={mem.id}>{mem.email}</option>)}
            </select>
        </FormGroup>
        <FormGroup label_for="licenseType" label="Lizenz">
            <select required id="licenseType" name="licenseType" className="form-control">
                {/*<option value="1">Test</option>
                <option value="2">Keine</option>
                <option value="3">Lizenz</option>
                <option value="6">telani Next</option>*/}
                <option value="8">telani Insight</option>
                <option value="9">telani onSite</option>
                {/*<option value="10">Concept</option>*/}
            </select>
        </FormGroup>
        <FormGroup label_for="expiration_date" label="Ablaufdatum">
            <input type="date" ref={dateElement} id="expiration_date" name="expiration_date" className="form-control"  />
            <button type="button" onClick={clearDate} className="right">Datum löschen</button>
        </FormGroup>
        {message_block}
        <input type="submit" value="Hinzufügen" />
    </Form>
    </>
}