// @ts-check
import React, { useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLoaderData, redirect, json } from "react-router";
import { useTitle } from "./AdminRoot.js";
import { GoToLogin } from "./react-entry.js";

/**
 * @typedef Message
 * @property {boolean} isError
 * @property {string} message
 */

export async function referenceDataLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    if (params.referenceId !== null && params.referenceId !== undefined){
        const response = await fetch(__API_URL__ + `/webapi/reference/${params.referenceId}`,{
            signal: request.signal
        });
        if (response.status === 401){
            return redirect(login_url);
        }
        const data = await response.json();
        return data;
    }
    return null;
}


export function Reference(){

    const loaderData = useLoaderData();

    const navigate = useNavigate();

    let { referenceId } = useParams();
    if (referenceId === undefined){
        referenceId = null;
    }
    

    if (referenceId === null){
        useTitle("Referenz erstellen");
    }
    else{
        useTitle("Referenz bearbeiten");
    }
    

    const [token, setToken] = useState("");
    const [delete_token, setDeleteToken] = useState("");


    let initalFormData = {
        title: "",
        teaserimageupload: "",
        teaser_square_img: "",
        teaser_square_img_large: "",
        teaser_text: "",
        fullimageupload: "",
        full_img: "",
        full_img_large: "",
        license_text: "",
        show_on_homepage: true
    }

    if (loaderData !== null){
        initalFormData = {
            title: loaderData.title ?? "",
            teaserimageupload: "",
            teaser_square_img: loaderData.teaserURL ?? "",
            teaser_square_img_large: loaderData.teaserURLLarge ?? "",
            teaser_text: loaderData.teaserText ?? "",
            fullimageupload: "",
            full_img: loaderData.imageURL ?? "",
            full_img_large: loaderData.imageURL ?? "",
            license_text: loaderData.licenseText ?? "",
            show_on_homepage: loaderData.showOnHomepage ?? ""
        }
    }



    const [formData, setFormData] = useState(initalFormData);

    const [message, setMessage] = useState(/** @type {Message | null} */(null));

    useEffect(() => {
        fetch(__API_URL__ + '/webapi/createReferenceToken')
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setToken(data["token"]);
            })
    }, []);

    useEffect(() => {
        fetch(__API_URL__ + '/webapi/deleteReferenceToken')
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setDeleteToken(data["token"]);
            })
    }, []);

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    
    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
    
        setFormData({
          ...formData,
          [name]: value
        });
      };

    const handleFileInputChange = (e) => {
        const { name, value } = e.target;
        const file = e.target.files[0];

        setFormData({
            ...formData,
            [name]: file
          });
    }

    const deleteReferenceClicked = () => {
        fetch(__API_URL__ + `/webapi/reference/${referenceId}/${delete_token}`, {method: "DELETE"})
        .then(response => response.json())
        .then(data => {
            if (data.success){
                navigate("/admin/references");
            }
            else{
                console.error('Error:', data);
                setMessage({isError: true, message: "Es ist ein Fehler aufgetreten."});
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert("Bei der Verarbeitung ist ein Fehler aufgetreten.");
            setMessage({isError: true, message: "Es ist ein Fehler aufgetreten."});
        });
    }

    function handleSubmit(ev){
        ev.preventDefault();
        setMessage(null);
        
        const theForm = new FormData();
        theForm.append("create_reference_form[title]", formData.title);
        theForm.append("create_reference_form[title]", formData.title);
        theForm.append("create_reference_form[teaserimageupload]", formData.teaserimageupload);
        theForm.append("create_reference_form[teaser_square_img]", formData.teaser_square_img);
        theForm.append("create_reference_form[teaser_square_img_large]", formData.teaser_square_img_large);
        theForm.append("create_reference_form[teaser_text]", formData.teaser_text);
        theForm.append("create_reference_form[fullimageupload]", formData.fullimageupload);
        theForm.append("create_reference_form[full_img]", formData.full_img);
        theForm.append("create_reference_form[full_img_large]", formData.full_img_large);
        theForm.append("create_reference_form[license_text]", formData.license_text);
        if (formData.show_on_homepage) {
            theForm.append("create_reference_form[show_on_homepage]", "1");
        }
        theForm.append("create_reference_form[_token]", token);
        
        let url = `/webapi/create-reference`;
        if (referenceId !== null){
            url = `/webapi/edit-reference/${referenceId}`
        }

        fetch(__API_URL__ + url, {method: "POST", body: theForm})
                .then(response => response.json())
                .then(data => {
                    if (data.success){
                        if (referenceId === null){
                            setMessage({isError: false, message: "Referenz erstellt."});
                            navigate("/admin/references");
                        }
                        else{
                            setMessage({isError: false, message: "Referenz gespeichert."});
                        }

                    }
                    else{
                        setMessage({isError: true, message: "Es ist ein Fehler aufgetreten."});
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert("Bei der Verarbeitung ist ein Fehler aufgetreten.");
                    setMessage({isError: true, message: "Es ist ein Fehler aufgetreten."});
                });
    }

    return <>
    <NavLink to="/admin/references">&#x21E6; Zur&uuml;ck</NavLink>
    {referenceId !== null && <a href={"/reference/" + referenceId}>Zur Referenz</a>}
    <form name="create_reference_form" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
        <div>    
            <label htmlFor="create_reference_form_title" className="required">
                Titel
                <input type="text" id="create_reference_form_title" 
                    name="title" 
                    value={formData.title}
                    onChange={handleInputChange} 
                    required className="form-control form-control" />
            </label>
        </div>
        <div>    
            <label htmlFor="create_reference_form_teaserimageupload">
                Square teaser image (Best size: 700px)
                <input type="file" id="create_reference_form_teaserimageupload" 
                    name="teaserimageupload"
                    onChange={handleFileInputChange} 
                    className="form-control" />
            </label>
        </div>
        <div>    
            <label htmlFor="create_reference_form_teaser_square_img">
                Square teaser image (Width & height: 350px)
                <input type="text" id="create_reference_form_teaser_square_img"
                    name="teaser_square_img"
                    disabled={formData.teaserimageupload !== ""}
                    value={formData.teaser_square_img}
                    onChange={handleInputChange} 
                    className="form-control form-control" />
            </label>
        </div>
        <div>
            <label htmlFor="create_reference_form_teaser_square_img_large">
                High DPI Square teaser image (Width & height: 700px)
                <input type="text" id="create_reference_form_teaser_square_img_large"
                    name="teaser_square_img_large"
                    disabled={formData.teaserimageupload !== ""}
                    value={formData.teaser_square_img_large}
                    onChange={handleInputChange} 
                    className="form-control form-control" />
            </label>
        </div>
        <div>
            <label htmlFor="create_reference_form_teaser_text">
                Teaser text
                <textarea id="create_reference_form_teaser_text" 
                    name="teaser_text"
                    value={formData.teaser_text} 
                    onChange={handleInputChange} 
                    className="form-control form-control">
                    </textarea>
            </label>
        </div>
        <div>    
            <label htmlFor="create_reference_form_fullimageupload">
                Large image (best width: 1800px)
                <input type="file" id="create_reference_form_fullimageupload" 
                    name="fullimageupload"
                    onChange={handleFileInputChange} 
                    className="form-control" />
            </label>
        </div>
        <div>    
            <label htmlFor="create_reference_form_full_img">
                Large image (Width: 900px)
                <input type="text" id="create_reference_form_full_img" 
                    name="full_img"
                    disabled={formData.fullimageupload !== ""}
                    value={formData.full_img}
                    onChange={handleInputChange} 
                    className="form-control form-control" />
            </label>
        </div>
        <div>
            <label htmlFor="create_reference_form_full_img_large">
                High DPI large image (Width: {'>'}1000px)
                <input type="text" id="create_reference_form_full_img_large" 
                    name="full_img_large"
                    disabled={formData.fullimageupload !== ""}
                    value={formData.full_img_large} 
                    onChange={handleInputChange} 
                    className="form-control form-control" />
            </label>
        </div>
        <div>    
            <label htmlFor="create_reference_form_license_text">
                License text
                <textarea id="create_reference_form_license_text" 
                    name="license_text" 
                    value={formData.license_text}
                    onChange={handleInputChange} 
                    className="form-control">
                </textarea>
            </label>
        </div>
        <div className="form-group">
            <div>
                <div className="checkbox">
                    <label className="required" htmlFor="create_reference_form_show_on_homepage"> 
                        <input type="checkbox"
                            id="create_reference_form_show_on_homepage"
                            name="show_on_homepage"
                            onChange={handleInputChange} 
                            value="1"
                            checked={formData.show_on_homepage} />
                        <span>
                        Auf Startseite anzeigen
                        </span>
                    </label>
                </div>
            </div>
        </div>
        {message_block}
        <div className="clearfix">
            <input type="submit" value="Speichern" className="btn btn-primary pull-right" />
        </div>
        {referenceId !== null && 
        <>
        <hr />
        <a className="right" href="#" onClick={deleteReferenceClicked}>Referenz löschen</a>
        </>
        }
    </form>
    </>
}
