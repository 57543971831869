// @ts-check
import React, { useState, useEffect} from "react";
import { Outlet, NavLink, Routes, Route, useOutletContext, useParams, useLoaderData, redirect, Await, useAsyncError, Navigate } from "react-router";
import { MainWrapper } from "./MainWrapper.js";
import { fetchUser } from "./APIHelpers/Users.js";

export async function accountLoader({params, request}){
    let need_to_fetch_signed_in_user = false;
    if (params.userId !== undefined){
        need_to_fetch_signed_in_user = true;
    }
    const target_user = fetchUser(params.userId, request.signal).catch();

    let canManagePoolPromise = target_user.then(user => (user.licenseType == 1 || user.licenseType == 6) && user.licensePool !== "");

    let signed_in_user = null;
    if (need_to_fetch_signed_in_user){
        signed_in_user = fetchUser(null, request.signal);
    }
    else{
        signed_in_user = target_user;
    }
    const isAdminPromise = signed_in_user.then(user => {
        let roles = user.roles ?? [];
        if (!Array.isArray(roles))
        {
            roles = Object.values(roles);
        }
        return roles.includes("ROLE_ADMIN");
    })
    return {canManagePoolPromise, isAdminPromise};
}

export function useTitle(title){
  const { setTitle } = useOutletContext();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};

export function AccountRoot(){

    const {isAdminPromise, canManagePoolPromise} = useLoaderData();



    const [title, setTitle] = useState("");

    const { userId } = useParams();

    function linkStyle({isActive}){
      return isActive ? "small button" :  "small button alt";
    }

    function makeTarget(url){
        if (userId !== null && userId !== undefined){
            return url + "/" + userId;
        }
        return url;
    }

    return (
    <MainWrapper>
        <h2>{title}</h2>
        <ul className="nav telani-nav-tabs" role="tablist">
            <li role="presentation">
            <NavLink viewTransition className={linkStyle} end to={makeTarget("/account")} role="tab">telani</NavLink>
            </li>
            <li role="presentation">
            <NavLink viewTransition className={linkStyle} end to={makeTarget("/account/edit")} role="tab">Profil</NavLink>
            </li>
            <li role="presentation">
            <NavLink viewTransition className={linkStyle} to={makeTarget("/account/changePw")} role="tab">Passwort ändern</NavLink>
            </li>
            <li role="presentation">
            <NavLink viewTransition className={linkStyle} to={makeTarget("/account/manage_subscription")} role="tab">Jahres-Abo</NavLink>
            </li>
            <React.Suspense fallback={null}>
                <Await
                    resolve={isAdminPromise}
                    errorElement={<MenuErrorHandler />}>
                        {(isAdmin) => (
                isAdmin &&
                <li role="presentation">
                    <NavLink viewTransition className={linkStyle} to={makeTarget("/account/advanced-details")} role="tab">Erweitert</NavLink>
                </li>
                        )}
                </Await>
            </React.Suspense>
            <React.Suspense fallback={null}>
                <Await
                    resolve={canManagePoolPromise}
                    errorElement={<MenuErrorHandler />}>
                        {(canManagePool) => (
            canManagePool &&
            <li role="presentation">
            <NavLink viewTransition className={linkStyle} to={makeTarget("/account/my-pool")} role="tab">Viewer verwalten</NavLink>
            </li>                        )}
                </Await>
            </React.Suspense>
        </ul>
        <Outlet context={{ title, setTitle }}/>
    </MainWrapper>
    );
}


function MenuErrorHandler(){
    

    const login_url = "/login?next=" + encodeURIComponent(window.location.href);
    const error = useAsyncError();
    console.log("myError", error);
    if (error.message === "not logged in"){
        return <Navigate to={login_url} />
    }
    return null;
}
