// @ts-check
import React, { useEffect, useState } from "react"
import { ProductBox } from './ProductBox/ProductBox.js';
import { Step } from './Step/Step.js';
import { useTitle } from "./AdminRoot.js";
import { redirect, useLoaderData } from "react-router";


export async function subscriptionFirstStepLoader({request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let url = "/webapi/userinfo";
    let res = await fetch(__API_URL__ + url,{
        signal: request.signal
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    return await res.json();
}

export function SubscriptionFirstStep() {

    // We load this user to force login, with the goal of enabling a nicer UX 
    const user = useLoaderData();

    useTitle("Kaufen Sie jetzt Ihr telani Jahre-Abo");

    return (
        <>
            <Step Title="Produkt">
                <ProductBox />
            </Step>
            <Step Title="Bestellung auf Rechnung">
                <p>Gerne erstellen wir Ihnen ein <strong>individuelles Angebot</strong> und bieten Ihnen <strong>Zahlung per Rechnung</strong> an.</p>
                <p>Bitte nehmen Sie dazu Kontakt mit uns auf:
                <span className="align-center block"><strong><a href="mailto:info@telani.net">info@telani.net</a></strong> oder <strong>07663-99948</strong></span>
                </p>
            </Step>
        </>
    );
}