import { MainWrapper } from "./MainWrapper.js";
import React from "react";
import { useRouteError } from 'react-router';


export function ErrorPage (){

    const error = useRouteError();
    console.error(error);

    return <MainWrapper majorTitle="Es ist ein Fehler aufgetreten">
            <section>
                <article className="centeredsmall">
                <p>Leider konnte Ihre Anfrage nicht erfolgreich bearbeitet werden. Bitte versuchen Sie es erneut.</p>
                </article>
            </section>
        </MainWrapper>
}